import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

// styling for Stripe input component fields
// https://stripe.com/docs/js/appendix/style


const StripeForm = (props) => {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: 'black',
        fontSize: '16px',
        lineHeight: '38px',
        fontFamily: 'Roboto',
        '::placeholder': {
          color: '#C4C5C7',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };


  const [zipCode, setZipCode] = useState('')

  var has_subscription = false;
  for (const line of props.lineitems) {
    if ('subscription-plan-id' in line['extra_data']) {
      has_subscription = true;
      break;
    }
  }

  return (
    <form
      className={`ParticipantStripeForm ${props.orderIsProcessing ? 'enabled' : 'disabled'}`}
    >
      {(props.total > 0 || has_subscription) &&
        <div className='StripeContainer'>
          <h2 className='v2-subheader-small'>Payment Information</h2>
          <div className="card-number">
            <p className="card-label v2-input-label">Credit Card Number</p>
            <div className="card-element card-number-element">
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
              <img className="credit-card-logos" src='/static/img/logos/credit-card-logos.svg' />
            </div>
          </div>
          <div className="card-second-row">
            <div className="card-expiration-date">
              <p className="card-label v2-input-label">Expiration Date</p>
              <div className="card-element card-expiration-date-cvc-element">
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
              </div>
            </div>
            <div className="card-cvc">
              <p className="card-label v2-input-label">Security Code</p>
              <div className="card-element card-expiration-date-cvc-element">
                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                <img className="cvc-logo" src='/static/img/logos/cvc-logo.svg' />
              </div>
            </div>
          </div>
          <div className="billing-zip-code">
            <p className="card-label v2-input-label">Billing Zip Code</p>
            <input
              className="billing-zip-code-input"
              type="text"
              maxLength="5"
              inputMode="numeric"
              autoComplete="postal-code"
              placeholder="00000"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
            />
          </div>

        </div>
      }

      <button
        className='order-btn v2-button'
        name='submit-order'
        onClick={(evt) => props.submitOrder(evt, props.stripe, props.elements, zipCode)}>
        {props.orderIsProcessing ? 'Submitting...' : `Pay Now $${props.total}`}
      </button>
      <div className="stripe-text">
        <p className='v2-input-label'>powered by </p><img className='stripe-logo' alt='stripe' src='/static/img/logos/stripe.svg' />
      </div>

    </form>
  );

};

StripeForm.propTypes = {
  stripe: PropTypes.object,
  elements: PropTypes.object,
  subTotal: PropTypes.number,
  lineitems: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  submitOrder: PropTypes.func.isRequired,
  orderIsProcessing: PropTypes.bool.isRequired
}

export default StripeForm;
