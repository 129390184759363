import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const ProgressBar = (props) => {
  const navigate = useNavigate();

  const onSelectPreviousStep = (index) => {
    let path;
    if (props.stepUrls.length == 0) {
      const steps = props.currentStep - index;
      path = '';
      for (let i = 0; i < steps; i++) {
        path += '../';
      }
      navigate(path, { relative: 'path' });
    } else {
      window.location.href = props.stepUrls[index];
    }

  };

  const barSections = [];
  const stepNames = [];

  for (const [index, value] of props.steps.entries()) {
    if (index <= props.currentStep) {
      barSections.push(<div
        className={`bar-section filled ${index < props.currentStep && props.showLinks ? 'clickable' : ''}`}
        key={index}
        onClick={() => { if (props.showLinks) { onSelectPreviousStep(index); } }}
      ><div className='filled' /></div>);
    } else {
      barSections.push(<div className="bar-section" key={index}></div>);
    }

    if (index < props.currentStep) {
      stepNames.push(
        <span className="step-name previous-step"
          onClick={() => { onSelectPreviousStep(index) }}
          key={index}>
          {value}
        </span>
      );
    } else {
      stepNames.push(<span className="step-name" key={index}>{value}</span>);
    }
  }

  if (props.currentStep > props.steps.length)
    barSections.push(<div className="bar-section filled" key={props.steps.length}></div>);
  else
    barSections.push(<div className="bar-section" key={props.steps.length}></div>);

  return (
    <div className="progress-bar">
      <div className="bar">
        {barSections}
      </div>

      <div className="steps">
        {stepNames}
      </div>
    </div>
  )
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  showLinks: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  stepUrls: PropTypes.array
};

ProgressBar.defaultProps = {
  showLinks: true,
  stepUrls: []
}

export default ProgressBar;
