import PropTypes from 'prop-types'
import React from 'react'


export default function CheckoutTotal(props) {
    // Displays cart total for CartCheckout page
    let showSubTotal = props.subTotal ? props.subTotal != props.total : false
    return (
        <div className='CheckoutTotal'>
            {showSubTotal &&
                <div className='CheckoutTotal__line'>
                    <h3 className="v2-accent-big-bold">Subtotal</h3>
                    <h3 className="v2-accent-big-bold">${props.subTotal}</h3>
                </div>
            }
            <div className='CheckoutTotal__line'>
                <h3 className="v2-accent-big-bold">Total</h3>
                <h3 className="v2-accent-big-bold">${props.total}</h3>
            </div>
        </div>
    )
}

CheckoutTotal.propTypes = {
    subTotal: PropTypes.number,
    total: PropTypes.number.isRequired,
}
