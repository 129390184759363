import PropTypes from 'prop-types'
import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import urls from '../constants.js'
import { gtmPurchaseEvent, friendBuyOrder } from '../analytics.js'
import { applyPromo, applyGiftCard, createOrder } from './api.js'
import LoadingWheel from './LoadingWheel.jsx'
import LineItemList from './LineItemList.jsx'
import CheckoutTotal from './CheckoutTotal.jsx'
import DiscountCodeForm from './DiscountCodeForm.jsx'
import DiscountTotal from './DiscountTotal.jsx'
import StripeForm from './StripeForm.jsx'
import CartNotificationContainer from './CartNotificationContainer.jsx'
import {
  arraysEqual, bankersRound, isArray, isFunction,
  isString, pick, collapse_whitespace
} from '../util.js'


const stripePromise = loadStripe(document.STRIPE_PUBLIC_KEY);

// default error dict
const BASE_ERRORS = {
  general: [],
  promo_code: [],
  giftcard_code: [],
  stripe: [],
  product: [],
}

export default class CartPayment extends React.Component {

  static propTypes = {
    lineitems: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    learners: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      user: props.user,
      learners: props.learners,
      lineitems: props.lineitems,
      errors: { ...BASE_ERRORS },
      stripe_token: '',
      promo: null,  // the applied and returned promo object
      promo_code: '', // input promo code
      applied_giftcard: false,
      giftcard_code: '',
      total_after_discount: null,
      orderSuccess: false,
      orderIsProcessing: false,  // loading wheel present when True
      order_number: null,
      payment_details: {},
    }
  }

  componentDidMount() {
    // Make sure that when a lineitem w/ an enrollable product has a
    // quantity greater than 1 that only the first participant defaults
    // to the purchaser.
    let lines = this.state.lineitems
    if (lines.length < 1) window.location.replace(urls.cartCheckoutUrl)
  }

  getDiscount = () => {
    // Get the promo amount applied to the order
    return typeof this.state.total_after_discount === 'number'
      ? bankersRound(this.getSubtotal() - this.state.total_after_discount, 2)
      : null
  }

  getSubtotal = () => {
    // Get the order total without discounts or credit applied
    let subtotal = 0
    this.state.lineitems.forEach(line => {
      if (line.children.length > 0) {
        line.children.forEach(child => {
          subtotal += child.quantity * child.price
        })
      }
      subtotal += line.quantity * line.price
    })
    return subtotal
  }

  getTotalAfterDiscount = () => {
    // If a promo was applied return the total_after_discount
    // else return the subtotal
    return typeof this.state.total_after_discount === 'number'
      ? this.state.total_after_discount
      : this.getSubtotal()
  }

  getFinalTotal = () => {
    // Get the order total after discount and credit applied
    return this.getTotalAfterDiscount() - this.getAppliedCredit()
  }

  getAppliedCredit = () => {
    // Get the amount of user credit applied to the order
    return Math.min(this.getTotalAfterDiscount(),
      parseFloat(this.props.user.credit || 0))
  }

  handleCodeChange = evt => {
    // handle giftcard and promo code changes
    let stateObj = {}
    let val = collapse_whitespace(evt.target.value)
    val = val.replace(/^\s+/g, '')  // prevent leading whitespace
    stateObj[evt.target.name] = val
    this.setState(stateObj)

    // when input is clear of content we clear related errors as well
    if (!val.trim()) {
      evt.target.value = ''  // reset input
      this.clearErrors(evt.target.name)
    }
  }

  setPromo = response => {
    // Set the applied promo and total
    let new_total = parseFloat(response.price_after_discount)
    this.setState({
      promo: response.promo_data,
      promo_code: response.promo_data.code || '',
      total_after_discount: new_total,
    }, () => {
      // clear promo related errors and stripe errors if price hits zero
      this.clearErrors('promo_code')
      if (new_total == 0) this.clearErrors('stripe')
    })
  }

  submitPromo = e => {
    // execute call to apply a promo code
    e.preventDefault()
    if (this.state.promo_code) {
      // make a copy of the fields you need to identify the lineitems
      let lineItemsCopy = []
      this.state.lineitems.forEach(li => {
        lineItemsCopy.push(
          pick(li, 'id', 'object_id', 'quantity', 'template_id'))
      })
      applyPromo(
        this.state.promo_code.trim(),
        lineItemsCopy,
        response => { this.setPromo(response) }, // success
        response => {
          // promo failed, so update w/ the errors
          this.updateErrorArray(response, 'promo_code', true)
        })
    }
  }

  submitGiftCard = e => {
    // execute call to redeem a gift card
    e.preventDefault()
    if (this.state.giftcard_code) {
      applyGiftCard(this.state.giftcard_code,
        response => {
          // Success callback
          // update the user credit from the response and clear
          // all giftcard related errors on the current order
          let user = this.state.user
          user.credit = parseFloat(response.credit)
          this.setState({ user: user })
          this.setState({ applied_giftcard: true })
          this.clearErrors('giftcard_code')
        },
        response => {
          // giftcard failed. Update the error dict
          this.updateErrorArray(response, 'giftcard_code', true)
        },
      )
    }
  }

  copyErrors = errors => {
    // this is a general util function for returning a deep copy of
    // the internal error state dict. We do this because react
    // does not handle updating nested state well.
    return JSON.parse(JSON.stringify(errors || this.state.errors))
  }

  clearErrors = (key = null, callback = null) => {
    // clear the messages array if a specific key is given, otherwise
    // we clear all message arrays in the entire error dict.
    // NOTE: we only clear errors if they are there to be cleared
    if (callback === null) callback = () => { }
    if (this.hasAnyErrors() === false) return callback(this.state.errors)

    // clear everything when the key is null
    if (key === null) {
      this.setState({ errors: { ...BASE_ERRORS } }, () => {
        if (isFunction(callback)) callback(this.state.errors)
      })
      return
    }

    // make sure the keys we want to clear are in an array and
    // only clear out the relevant keys (if they have errors)
    let keys = isArray(key) ? key : [key]
    let update_flag = false
    let errors = this.copyErrors()
    for (let err_type of keys) {
      if (errors[err_type].length > 0) {
        errors[err_type] = []
        update_flag = true
      }
    }

    // avoid updating the state unless something actually changed
    if (update_flag === true) {
      this.setState({ errors: errors }, () => {
        callback(this.state.errors)
      })
    } else {
      callback(this.state.errors)
    }
  }

  hasAnyErrors = (errors = null) => {
    // check if any error messages exist in the error object
    errors = errors || this.state.errors
    for (const key in errors) {
      if (errors[key].length > 0) return true
    }
    return false
  }

  hasError = (message, key = null, errors = null) => {
    // return true if the message exists in the array of the given key
    // if no key is given then it checks all tracked message arrays
    errors = errors || this.state.errors
    for (const cat in errors) {
      if (key && key != cat) continue
      for (let i = 0; i < errors[cat].length; i++) {
        if (errors[cat][i] == message) return true
      }
    }
    return false
  }

  setError = (err = 'Unknown Error Occured', key = null, callback = null) => {
    // Assigns error messages to their respective key in the error
    // object. If no key is provided we default to 'general'.
    // NOTE: it will not set the message if its already present.
    key = key || 'general'

    if (!this.state.errors.hasOwnProperty(key)) {
      let exception = new Error(`"${key}" not found in errors obj.`)
      exception.name = 'KeyError'
      throw exception
    }

    if (!this.hasError(err, key, this.state.errors)) {
      let errors = this.copyErrors()
      errors[key].push(err)
      this.setState({ errors: errors }, () => {
        if (isFunction(callback)) callback(errors)
      })
    } else {
      if (isFunction(callback)) callback()
    }
  }

  updateErrorArray = (error_array, key, replace = false, callback = null) => {
    // add all given errors to the existing error array
    // if :replace: is true then replace the array entirely
    var errors = this.copyErrors()
    var new_array = [...error_array]
    if (replace === false) {
      let uniq = new Set([...errors[key], ...error_array])
      new_array = [...uniq]
    }

    // only update the array if something changed
    if (!arraysEqual(errors[key], new_array)) {
      errors[key] = new_array
      this.setState({ errors: errors }, () => {
        if (isFunction(callback)) callback(errors)
      })
    } else {
      if (isFunction(callback)) callback(errors)
    }
  }

  filterErrors = (errors = null) => {
    // strip away all key/val pairs in the error object that don't
    // contain any error data. This ensures that empty data does not
    // get passed into the Notification components.
    errors = errors || this.copyErrors()
    return Object.keys(errors)
      .filter(key => errors[key].length > 0)
      .reduce((obj, key) => {
        obj[key] = this.messagesToString(key, errors[key])
        return obj
      }, {})  // return empty object if no errors are found.
  }

  messagesToString = (category, messages) => {
    // this handles reformatting error messages for user consumption.
    // each error category (e.g. participants, promos, etc) has their own
    // unique error data that may or may not need to be coerced to a string
    return messages.map(msg => {
      if (isString(msg)) return msg
      return JSON.stringify(msg)
    })
  }

  getOrderData = (stripe_token = null) => {
    // create order object for submission; returns order_data
    return {
      'stripe_id': stripe_token,
      'user': this.state.user,
      'promo_code': this.state.promo_code,
      'giftcard_code': this.state.giftcard_code,
      'line_items': this.state.lineitems,
      'total_after_discount': this.getTotalAfterDiscount(),
    }
  }

  isSubscription = () => {
    let isSub = false;
    this.state.lineitems.forEach((item) => {
      if (item.stripe_plan) {
        isSub = true;
      }
    });
    return isSub;
  }

  hasPrivateLesson = () => {
    let isPrivate = false;
    this.state.lineitems.forEach((item) => {
      if (item.content_type === 'privatelessonpackage') {
        isPrivate = true;
      }
    });
    return isPrivate;
  }

  validateOrder = () => {
    // Validate the entire order (run before order submission)
    return new Promise((resolve, reject) => {
      this.clearErrors(['general', 'stripe', 'product'], () => {
        if (this.hasAnyErrors(this.state.errors)) {
          reject(this.state.errors)
        } else {
          resolve()
        }
      })
    })
  }

  makeStripeToken = (stripe, elements, zipCode) => {
    // get a unique token from stripe so we can charge the user.
    // NOTE: the stripe.createToken promise resolves known stripe
    // errors instead of rejecting them.
    return new Promise((resolve, reject) => {
      // if the balance is zero then no need to get a stripe token
      if (this.getFinalTotal() <= 0 && !this.isSubscription()) {
        resolve(this.getOrderData())
        return
      }

      const cardNumberElement = elements.getElement(CardNumberElement);

      stripe.createToken(cardNumberElement, {
        name: `${this.state.user.first_name} ${this.state.user.last_name}`,
        address_zip: zipCode,
      }).then(result => {
        if (result.error) {
          this.setError(result.error.message, 'stripe', () => {
            reject(result)
          })
        } else {
          resolve(this.getOrderData(result.token))
        }
      })

    })
  }

  runCreateOrder = data => {
    return new Promise((resolve, reject) => {
      createOrder(data,
        // success callback sets the receipt page data
        response => {
          let result = {
            orderSuccess: true,
            orderIsProcessing: false,
            order_number: response.order_number,
            payment_details: response.payment_details
          }
          this.setState(result, () => {
            this.runAnalytics(data)
            resolve(response)
          })
        },
        // fail callback for displaying server side errors
        response => {
          let result = {
            orderSuccess: false,
            orderIsProcessing: false
          }
          this.setState(result, () => {
            this.updateErrorArray(
              response.errors, response.error_type, false, () => {
                reject(response)
              })
          })
        },
      )  // end createOrder
    })
  }

  runAnalytics = data => {
    // update analytics libraries w/ the order info
    gtmPurchaseEvent(this.state.order_number, data);
    friendBuyOrder(this.state.order_number, data);
  }

  submitOrder = (evt, stripe, elements, zipCode) => {
    // Submit the order serverside for processing
    // Here we convert the major steps of the order into Promises
    // so that we can guarantee a predictable control flow.
    evt.preventDefault()

    this.setState({ orderIsProcessing: true }, () => {
      window.autoScrollTo('header')

      // execute the order process in its sequential steps.
      this.validateOrder()
        .then(() => this.makeStripeToken(stripe, elements, zipCode))
        .then(this.runCreateOrder)
        .catch(data => {
          // handle orders that terminated early
          console.log('order failed:', data)
        })
        .finally(() => {
          // make sure that regardless of success or failure of
          // the order that the processing flag gets switched off
          this.setState({ orderIsProcessing: false })
        })
    })

  }

  render() {
    let headline_chunk = null
    let loading_wheel = null
    let hide_elements = ''
    let adjusted_style = ''
    let applied_credit = this.getAppliedCredit()

    if (this.state.orderIsProcessing &&
      !this.hasAnyErrors() &&
      this.state.orderSuccess === false
    ) {
      loading_wheel = (<LoadingWheel />)
      hide_elements = 'cart_hide_element'
    }

    if (this.state.orderIsProcessing || this.state.orderSuccess) {
      adjusted_style = 'adjusted_style'
    }

    if (this.state.orderSuccess) {
      // this is the header on the receipt page when the order is successful
      headline_chunk = (
        <div className='CartCheckoutHeader CartReceipt'>
          {loading_wheel}
          <h2 className='CartCheckout__header'>Order Details</h2>
          <div className='CartReceipt__details'>
            <div className='CartReceipt__section'>
              <h4>ORDER NUMBER</h4>
              <span>{this.state.order_number}</span>
            </div>
            <div className='CartReceipt__section'>
              <h4>STUDENT</h4>
              <span>
                {this.state.user.first_name} {this.state.user.last_name}<br />
                {this.state.user.email}<br />
                {this.state.user.phone_number}
              </span>
            </div>
            <div className='CartReceipt__section'>
              <h4>PAYMENT DETAILS</h4>
              {this.state.payment_details.brand &&
                <span>
                  {this.state.payment_details.brand}: **** **** **** {this.state.payment_details.last4}
                </span>
              }
            </div>
          </div>
          <img src={'https://shareasale.com/sale.cfm?amount=' + this.state.total_after_discount + '&tracking=' + this.state.order_number + '&transtype=sale&merchantID=70785'} width="1" height="1" alt="receipt" />
        </div>
      )
    } else {
      // order is still ongoing
      headline_chunk = (
        <div className='CartCheckoutHeader'>
          {loading_wheel}
          {!this.state.orderIsProcessing &&
            <div>
              <h2 className='CartCheckout__header v2-subheader-small'>Order Summary</h2>
            </div>
          }
        </div>
      )
    }

    return (
      <Elements stripe={stripePromise}>
        <CartNotificationContainer
          notifications={this.state.orderSuccess
            ? {
              'success': [this.hasPrivateLesson() ?
                `SUCCESS! CLICK <a href="${urls.profileUrls.accountOverview}">HERE</a> TO GO TO YOUR STUDENT ACCOUNT`
                : 'THANKS, SUCCESS! CHECK YOUR INBOX FOR NEXT STEPS']
            }
            : this.filterErrors()}
          message_type={this.state.orderSuccess ? 'success' : 'error'}
        />
        <div className={`CompletePayment ${adjusted_style}`}>

          <div className={`CartCheckout ${adjusted_style}`}>

            {headline_chunk}

            {!this.state.orderIsProcessing &&
              <LineItemList
                lineitems={this.state.lineitems}
                allowEdits={false}
              />
            }

            {!this.state.orderSuccess &&
              <div className={hide_elements}>
                <div className='cart_section DiscountContainer'>
                  <div className='DiscountContainer__forms'>
                    <DiscountCodeForm
                      name='promo_code'
                      submitMethod={this.submitPromo}
                      label='Promo Code'
                      placeholder='Enter Code'
                      submitButtonText='Apply'
                      value={this.state.promo_code}
                      handleChange={this.handleCodeChange}
                      appliedValue={this.state.total_after_discount != null
                        ? `Promo Discount:  -$${this.getDiscount()}`
                        : ''}
                      isAccepted={this.state.total_after_discount != null}
                      errors={this.state.errors.promo_code} />
                    <DiscountCodeForm
                      name='giftcard_code'
                      submitMethod={this.submitGiftCard}
                      label='Gift Card Code'
                      placeholder='GC-XXXXXXXX'
                      submitButtonText='Redeem'
                      value={this.state.giftcard_code}
                      handleChange={this.handleCodeChange}
                      appliedValue={applied_credit
                        ? `Order Credit:  -$${applied_credit}`
                        : ''}
                      isAccepted={this.state.applied_giftcard}
                      errors={this.state.errors.giftcard_code} />
                  </div>
                  <DiscountTotal
                    promo_amt={this.getDiscount()}
                    credit_amt={applied_credit} />

                </div>

                <CheckoutTotal
                  subTotal={this.getSubtotal()}
                  total={this.getFinalTotal()} />


              </div>
            }
          </div>
          {!this.state.orderSuccess &&
            <div className={`PaymentInformation ${hide_elements}`}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <StripeForm
                    stripe={stripe}
                    elements={elements}
                    lineitems={this.state.lineitems}
                    subTotal={this.getSubtotal()}
                    total={this.getFinalTotal()}
                    submitOrder={this.submitOrder}
                    orderIsProcessing={this.state.orderIsProcessing}
                  />
                )}
              </ElementsConsumer>
            </div>
          }
        </div>
      </Elements>
    )
  }
}
